import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Blog01 from '../images/blog/blog.jpg';
import Layout from '../layouts/index';
import logo from '../images/trato-logo.svg';
import Template from '../images/templates/plantilla.png';
import TemplatePortrait from '../images/templates/portada-arrendamiento.png';
import DemoForm from '../components/DemoForm';


function TemplateDetail({locale, location}) {
    const { t } = useTranslation()
  return (
    <Layout id="landing" locale={locale} bodyClass="page-home landing" inverted border location={location}>
      <div>
        {/* <section className="bg-grey">

          <div className="container">

                    <div className="row mb-5">
                        <div className="col-lg-9">
                            <div className="contract-container">
                                <div className="contract-preview bg-light p-4 shadow-sm border mb-3">
                                    <img src={logo} className="w-25 mb-5" alt="Trato Logo" />
                                    <h1 className="font-weight-bold">Contrato de arrendamiento</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis ante ac est pulvinar tempus. Etiam tellus dui, porta eget ligula sed, ultrices facilisis arcu. Pellentesque at arcu tempus, ullamcorper sapien at, consequat leo. Praesent aliquet magna vitae leo finibus placerat. Vivamus non lectus ullamcorper, consequat turpis ut, viverra lectus. Duis non fermentum tellus. Donec dignissim commodo enim, a porta nulla varius a. Curabitur rhoncus nulla ut enim mattis volutpat. Mauris facilisis lectus eleifend, placerat felis ac, vehicula dui. Praesent congue eget purus tincidunt ultricies. Mauris pulvinar eros vitae ante maximus interdum non ac est.</p>

                                    <p className="font-weight-bold mb-0">Created by:</p>
                                    <p className="mb-5">[Sender.FirstName] [Sender.LastName]</p>

                                    <h4 className="font-weight-bold mb-3">Freelance Contract Terms and Conditions</h4>
                                    <ol>
                                        <li className="mb-3"><span className="font-weight-bold">Retained Rights.</span> Each party will retain all right, title, and interest in and to its own Pre‐Existing Intellectual Property irrespective of any disclosure of such Pre‐Existing Intellectual Property to the other party, subject to any licenses granted herein.</li>
                                        <li className="mb-3"><span className="font-weight-bold">Pre‐Existing Intellectual Property.</span> Freelancer will not use any Freelancer or third party Pre‐Existing Intellectual Property in connection with this Contract unless Freelancer has the right to use it for Customer’s benefit. If Freelancer is not the owner of such Pre‐Existing Intellectual Property, Freelancer will obtain from the owner any rights as are necessary to enable Freelancer to comply with this Contract.</li>
                                        <li><span className="font-weight-bold">Ownership of Deliverables.</span> Subject to Freelancer and third party rights in Pre‐Existing Intellectual Property, all Deliverables, whether complete or in progress, and all Intellectual Property Rights related thereto shall belong to Customer, and Freelancer hereby assigns such rights to Customer. Freelancer agrees that Customer will own all patents, inventor’s certificates, utility models or other rights, copyrights or trade secrets covering the Deliverables and will have full rights to use the Deliverables without claim on the part of Freelancer for additional compensation and without challenge, opposition or interference by Freelancer and Freelancer will, and will cause each of its Personnel to, waive their respective moral rights therein. Freelancer will sign any necessary documents and will otherwise assist Customer in securing, maintaining and defending copyrights or other rights to protect the Deliverables in any country.</li>
                                    </ol>
                                </div>

                                <div className="contract-preview bg-light p-4 border shadow-sm">
                                    <ol>
                                        <li><span class="font-weight-bold">Right to Disclose.</span> With respect to any information, knowledge, or data disclosed to Customer by the Freelancer, the Freelancer warrants that the Freelancer has full and unrestricted right to disclose the same without incurring legal liability to others, and that Customer shall have full and unrestricted right to use and publish the same as it may see fit. Any restrictions on Customer’s use of any information, knowledge, or data disclosed by Freelancer must be made known to Customer as soon as practicable and in any event agreed upon before the start of any work</li>
                                    </ol>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut quis ante ac est pulvinar tempus. Etiam tellus dui, porta eget ligula sed, ultrices facilisis arcu. Pellentesque at arcu tempus, ullamcorper sapien at, consequat leo. Praesent aliquet magna vitae leo finibus placerat. Vivamus non lectus ullamcorper, consequat turpis ut, viverra lectus. Duis non fermentum tellus. Donec dignissim commodo enim, a porta nulla varius a. Curabitur rhoncus nulla ut enim mattis volutpat. Mauris facilisis lectus eleifend, placerat felis ac, vehicula dui. Praesent congue eget purus tincidunt ultricies. Mauris pulvinar eros vitae ante maximus interdum non ac est.</p>

                                    <p className="font-weight-bold mb-0">Created by:</p>
                                    <p className="mb-5">[Sender.FirstName] [Sender.LastName]</p>

                                    <h4 className="font-weight-bold mb-3">Freelance Contract Terms and Conditions</h4>
                                    <ol>
                                        <li className="mb-3"><span className="font-weight-bold">Retained Rights.</span> Each party will retain all right, title, and interest in and to its own Pre‐Existing Intellectual Property irrespective of any disclosure of such Pre‐Existing Intellectual Property to the other party, subject to any licenses granted herein.</li>
                                        <li className="mb-3"><span className="font-weight-bold">Pre‐Existing Intellectual Property.</span> Freelancer will not use any Freelancer or third party Pre‐Existing Intellectual Property in connection with this Contract unless Freelancer has the right to use it for Customer’s benefit. If Freelancer is not the owner of such Pre‐Existing Intellectual Property, Freelancer will obtain from the owner any rights as are necessary to enable Freelancer to comply with this Contract.</li>
                                        <li><span className="font-weight-bold">Ownership of Deliverables.</span> Subject to Freelancer and third party rights in Pre‐Existing Intellectual Property, all Deliverables, whether complete or in progress, and all Intellectual Property Rights related thereto shall belong to Customer, and Freelancer hereby assigns such rights to Customer. Freelancer agrees that Customer will own all patents, inventor’s certificates, utility models or other rights, copyrights or trade secrets covering the Deliverables and will have full rights to use the Deliverables without claim on the part of Freelancer for additional compensation and without challenge, opposition or interference by Freelancer and Freelancer will, and will cause each of its Personnel to, waive their respective moral rights therein. Freelancer will sign any necessary documents and will otherwise assist Customer in securing, maintaining and defending copyrights or other rights to protect the Deliverables in any country.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="position-fixed">
                                <Link className="button orange animate-up btn btn-secondary w-100">Probar Trato Starter</Link>
                            </div>
                        </div>
                    </div>


                    <h2 className="font-weight-bold text-center mb-3">Templates relacionados</h2>
                    <div className="row">
                        <div className="col-md-4">
                            <Link className="mb-4">
                                <img src={Template} className="img-fluid mb-2 border rounded" />
                                <span className="badge badge-primary orange display-inline-block mb-1 p-1">Arrendamiento</span>
                                <h5 className="text-dark">Contrato de Arrendamiento</h5>
                                <p className="text-muted">Simply add a Statement of Work to this free consulting contract template and customize it to fit the services you offer.</p>
                            </Link>
                        </div>
                        <div className="col-md-4">
                            <Link className="mb-4">
                                <img src={Template} className="img-fluid mb-2 border rounded" />
                                <span className="badge badge-primary orange display-inline-block mb-1 p-1">Arrendamiento</span>
                                <h5 className="text-dark">Contrato de Arrendamiento</h5>
                                <p className="text-muted">Simply add a Statement of Work to this free consulting contract template and customize it to fit the services you offer.</p>
                            </Link>
                        </div>
                    </div>


                </div>
            </section> */}
      </div>

      <section className="bg-grey py-0 border-top border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 d-flex">
              <div className="align-self-center">
                <h1 className="font-weight-bold">Contrato de Arrendamiento de Casa</h1>

              </div>
            </div>
            <div className="col-lg-7">
              <img src={TemplatePortrait} className="img-fluid mx-auto" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-0 border-bottom">
        <div className="row no-gutters">
          <div className="col-lg-7 bg-light py-5">
            <div className="container px-5">

              <h5 className="text-dark font-weight-bold mt-5">¿Qué es un contrato de arrendamiento?</h5>
              <p className="text-muted">El Contrato de Arrendamiento de Casa- habitación: Es un documento por virtud del cual una persona denominada (arrendador), ya sea éste persona física o moral, en su carácter de propietario o representante legal del propietario de una casa que no está sujeta a ningún régimen de propiedad en condominio se obliga mediante un contrato a otorgar a otra persona denominada (arrendatario) el uso y goce temporal para ocupar la casa a cambio de la obligación que tiene el arrendatario de pagar una suma de dinero llamada renta o alquiler. </p>
              <p className="text-muted">Nota:  “Una vez que las partes firman un contrato de arrendamiento de vivienda, usualmente, tanto arrendador como arrendatario estarán legalmente obligados a los términos del mismo, siempre que estos no vayan en contra de las leyes establecidas”.</p>

              <h5 className="text-dark font-weight-bold mt-5">¿Quienes participan en el contrato?</h5>
              <p className="text-muted"><span className="text-dark font-weight-bold">Arrendador:</span> Es la persona propietaria de una casa o con facultades legales suficientes para poder conceder el uso y goce temporal de una casa a otra persona.</p>
              <p className="text-muted"><span className="text-dark font-weight-bold">Arrendatario:</span> Es la persona con capacidad de ejercicio suficiente que pagará una renta o alquiler cada cierto tiempo con el fin de ocupar y habitar una casa.</p>

              <h5 className="text-dark font-weight-bold mt-5">Requisitos del contrato de arrendamiento de casa:</h5>
              <ul>
                <li className="text-muted"><span className="text-dark font-weight-bold">Datos generales de las partes:</span> Es importante identificar a cada una de las personas, arrendador y arrendatario, que firmarán el contrato a través de sus datos personales. Como lo son: el nombre completo (en caso de ser persona física) o la denominación o razón social (en caso de ser persona moral), nacionalidad, domicilio (calle o avenida; número de casa, departamento u oficina; colonia; Alcaldía o Municipio; código postal; y, Entidad Federativa), la Clave Única de Registro de Población (en caso de ser persona física), la clave del Registro Federal de Contribuyentes. Nombre y datos generales del propietario o de la persona con las facultades legales para rentar la propiedad (arrendador).</li>
                <li className="text-muted">En caso de que una de las partes sea persona moral, es recomendable identificar sus siguientes datos de constitución: distinguir a través de qué instrumento público se constituyó la persona moral si es escritura pública o póliza, en caso que haya sido por correduría pública número del instrumento público, la fecha en la que se constituyó, a través de qué Fedatario Público fue constituida si es notario público o corredor público, número del Fedatario Público, ciudad y Estado del Fedatario Público, y si es posible datos de inscripción del instrumento público en el Registro Público de la Propiedad y del Comercio.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 bg-grey-x">
            <div className="container px-5">
              <div className="card bg-light my-5 rounded-0 shadow-sm">
                <div className="card-header pt-2 pb-1 px-4">
                  <h5 className="font-weight-bold mb-0">Contrato de Arrendamiento</h5>
                </div>
                <div className="card-body  py-1 px-4">
                  <small className="d-inline-block mb-3">QUE CELEBRAN , A QUIEN EN LO SUCESIVO SE LE DENOMINARA COMO "ARRENDADORA" Y , A QUIEN EN LO SUCESIVO SE LE DENOMINARA COMO "ARRENDATARIO"; AL TENOR DE LAS SIGUIENTES DECLARACIONES Y CLÁUSULAS:</small>
                  <h5 className="text-center mb-3">DECLARACIONES</h5>
                  <small className="d-inline-block mb-1"><b>1.Declara la parte Arrendadora que:</b></small>
                  <small className="d-inline-block mb-1"><b>i)</b> Celebra el presente contrato bajo su propio derecho.</small>
                  <small className="d-inline-block mb-1"><b>ii)</b> Es una persona física, mayor de edad y cuenta con la capacidad legal para celebrar el presente contrato. Quien a la firma del presente contrato se identifica con tipo identificación número de identificación número identificación</small>
                  <small className="d-inline-block mb-1"><b>iii)</b> Su domicilio se ubica en insertar domicilio</small>
                  <small className="d-inline-block mb-1"><b>iv)</b> Que está legalmente facultado para dar en arrendamiento el inmueble en los términos y condiciones establecidos en este contrato.</small>
                  <small className="d-inline-block"><b>v)</b> Que es el único legítimo propietario del inmueble ubicado en dirección inmueble según consta en la escritura que se adjunta a este contrato como número anexo, y que es su deseo darlo en arrendamiento en favor del arrendatario.n</small>
                </div>
                <div className="card-footer p-4  mx-auto text-center">
                  <button className="btn button orange animate-up">Utilizar template</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="bg-grey py-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="card bg-light my-5 rounded-0 shadow-sm">
                <div className="card-body p-3 w-100">
                  <div className="row">
                    <div className="col-md-8">
                      <h3 className="font-weight-bold mb-1">Solicita una demo ahora</h3>
                      <p className="text-muted mb-0">Prueba <strong className="text-primary">Trato</strong> y transforma la gestión de tus contratos en un proceso eficiente.</p>
                    </div>
                    <div className="col-md-3 offset-md-1 d-flex">
                      <DemoForm className="align-self-center" type="template" />
                      {/*  <a href="https://pipedrivewebforms.com/form/6ac4c4d58ceeb7fcbc63c8bb55e7911f5662609" target="_blank" className="button orange animate-up d-inline-block align-self-center" >Solicita una demo</a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>


  );
}

export default TemplateDetail;
